export default [
  {
    active: "info",
    actionKey: "mb",
    name: "Mark Bundschuh",
    image: "img/board/mark.jpg",
    role: "President",
    email: "bundschuh.15@osu.edu",
    bio: `Your local computer guy.`,
    quote: `"Talk is cheap. Show me the code." - Linus Torvalds`,
    linkedin: "https://linkedin.com/in/mark-bundschuh-18a27a26a",
    github: "https://github.com/mbund",
  },
  {
    active: "info",
    actionKey: "jl",
    name: "Jackson Leverett",
    image: "img/board/jackson.jpg",
    role: "Vice President",
    email: "leverett.3@osu.edu",
    bio: `I like projects`,
    quote: `"A good programmer is someone who always looks both ways before crossing a one-way street." - Doug Linder"`,
    linkedin: "https://linkedin.com/in/jack-leverett-6b1532242",
    github: "https://github.com/jLevere",
  },
  {
    active: "info",
    actionKey: "ak",
    name: "Andrew Kingery",
    image: "img/board/andrew-kingery.jpg",
    role: "Treasurer",
    email: "kingery.39@osu.edu",
    bio: `I write code and ride bikes`,
    quote: `"It's the job that's never started as takes the longest to finish." - "Gaffer Gamgee", J.R.R. Tolkien`,
    linkedin: "https://www.linkedin.com/in/andrew-kingery-804455172",
    github: "https://github.com/kanderoo",
  },
];
